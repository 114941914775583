import {
  PATHS_TO_LOAD_ON,
  SCRIPT_PATH,
  STYLES_PATH,
  WEBCHAT_ROOT_ID,
} from './constants';

export function onRouteUpdate({ location }) {
  const currentPath = location.pathname.replace(/\/$/, '');

  const areWebchatResourcesloaded =
    !!document.querySelector(`script[src="${SCRIPT_PATH}"]`) &&
    !!document.querySelector(`link[href="${STYLES_PATH}"]`);

  const pageHasWebchatRoot = !!document.getElementById(WEBCHAT_ROOT_ID);

  if (
    PATHS_TO_LOAD_ON.includes(currentPath) &&
    !areWebchatResourcesloaded &&
    pageHasWebchatRoot
  ) {
    const chatScript = document.createElement('script');
    chatScript.src = SCRIPT_PATH;

    const chatStyles = document.createElement('link');
    chatStyles.rel = 'stylesheet';
    chatStyles.href = STYLES_PATH;

    document.head.appendChild(chatScript);
    document.head.appendChild(chatStyles);
  }
}
